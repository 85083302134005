import React, { Component } from 'react'
import { Link } from 'gatsby'
import classnames from 'classnames'
import Container from './Container.js'
import styles from './Header.module.sass'
import { mainNavItems } from '../constants.js'
import imgDoggo from '../images/tayl-logo.svg'

class Header extends Component {
  state = {
    tweenPosition: 0,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = e => {
    if (this.isTicking) return
    window.requestAnimationFrame(() => {
      this.isTicking = false
      const tweenPosition = Math.min(1, window.scrollY / 2 / 100)
      if (tweenPosition !== this.state.tweenPosition)
        this.setState({ tweenPosition })
    })
    this.isTicking = true
  }

  render() {
    const { isHeaderFilled } = this.props
    let { tweenPosition } = this.state
    tweenPosition = isHeaderFilled ? 1 : tweenPosition
    const s = {
      backgroundColor: `rgba(75,70,70,${tweenPosition})`,
      boxShadow: `0 2px 8px rgba(0,0,0,${tweenPosition / 8})`,
    }
    const headerClasses = classnames({
      [styles.header]: true,
      [styles.isFilled]: isHeaderFilled,
      [styles.scrolled]: tweenPosition === 1,
    })
    return (
      <div className={headerClasses} style={s}>
        <Container>
          <nav className={styles.nav}>
            <Link className={styles.logo} to="/">
              <img src={imgDoggo} alt="TAYL" />
            </Link>

            <ul className={styles.menu}>{mainNavItems.map(renderNav)}</ul>
          </nav>
        </Container>
      </div>
    )
  }
}

function renderNav({ name, url, offset = 0, isHighlighted }) {
  const link =
    url.indexOf('/') > 0 ? (
      <a href={url}>{name}</a>
    ) : (
      <Link to={url}>{name}</Link>
    )
  return (
    <li
      key={url}
      className={classnames({ [styles.isHighlighted]: isHighlighted })}
    >
      {link}
    </li>
  )
}

export default Header
