import React from 'react'
import styles from './Container.module.sass'

const Container = ({ children, ...props }) => (
  <div className={styles.container} {...props}>
    {children}
  </div>
)

export default Container
