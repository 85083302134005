import React from 'react'
import { Link } from 'gatsby'
import Container from './Container.js'
import imgUltimateMachine from '../images/ultimate-machine-logo-landscape.svg'

const year = new Date().getFullYear()

const Footer = () => {
  return (
    <div className="footer">
      <Container>
        <div className="row">
          <div className="col-xs-12">
            <ul>
              <li>
                <a href="https://my.tayl.app">Sign in</a>
              </li>
              <li>
                <Link to="/terms">Privacy & Terms</Link>
              </li>
              <li>
                <Link to="/support">Support</Link>
              </li>
            </ul>
          </div>

          <div className="secondary-links col-xs-12">
            <ul>
              <li>
                <a href="https://headwayapp.co/tayl-changelog">Changelog</a>
              </li>
              <li>
                <a href="https://www.podopi.com">Add Audio to Your Blog</a>
              </li>
            </ul>
          </div>

          <div className="hadegott col-xs-12">
            <div className="mark">
              <a href="https://www.ultimatemachine.se">
                <img src={imgUltimateMachine} alt="Ultimate Machine" />
              </a>
              <span className="copyright">© {year}</span>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Footer
