import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './Header.js'
import 'normalize.css'
import 'flexboxgrid'
import './Layout.sass'
import './Layout.module.sass'

const Layout = ({ isHeaderFilled, children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content:
                'TAYL is the app that gives your content a voice. 🗣 Turn any webpage, blog or article into audio, and listen instantly or later via Podcasts. 🎧 ✌️',
            },
            {
              name: 'keywords',
              content:
                'text-to-speech,podcast,website to audio,listen to website,listen to blogs,blog to podcast',
            },

            { name: 'og:url', content: 'https://www.tayl.app' },
            { name: 'og:title', content: 'Turn websites into Podcasts' },
            {
              name: 'og:description',
              content:
                'TAYL is the app that gives your content a voice. 🗣 Turn any webpage, blog or article into audio, and listen instantly or later via Podcasts. 🎧 ✌️',
            },
            {
              name: 'og:image',
              content:
                'https://gfx.ultimatemachine.se/tayl/promo/tayl-logo-cover.png',
            },
            { name: 'og:image:width', content: '1400' },
            { name: 'og:image:height', content: '1400' },
            { name: 'fb:app_id', content: '278324686175304' },

            { name: 'twitter:card', content: 'summary' },
            { name: 'twitter:site', content: '@TAYL_app' },
            { name: 'twitter:creator', content: '@miickel' },
          ]}
        >
          <html lang="en" />
        </Helmet>

        <Header
          siteTitle={data.site.siteMetadata.title}
          isHeaderFilled={isHeaderFilled}
        />

        {children}
      </>
    )}
  />
)

export default Layout
